export enum NumberInputValidationStates {
  SUCCESS = 'success',
  LOWER_LIMIT_ERROR = 'lowerLimitError',
  UPPER_LIMIT_ERROR = 'upperLimitError',
}

export enum ValidationAlignment {
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  BOTTOM = 'bottom',
}
